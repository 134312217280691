<template>
  <div class="row">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="bg-primary text-white avatar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-clock"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="12" cy="12" r="9"></circle>
            <polyline points="12 7 12 12 15 15"></polyline>
          </svg>
        </span>
      </div>
      <div class="col">
        <div class="font-weight-medium">Wait {{ waitRelativeTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reputation.workflow.displayaction.waitaction",
  props: {
    actor: Object,
  },
  computed: {
    waitRelativeTime() {
      let seconds = this.actor.actor_data.relative_seconds;
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? " day" : " days") : "";
      var hDisplay = h > 0 ? h + (h == 1 ? " hour" : " hours") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " minute" : " minutes") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return (
        [dDisplay, hDisplay, mDisplay, sDisplay]
          .filter((x) => x !== "")
          .join(", ") + "."
      );
    },
  },
};
</script>
