<template>
  <div>
    <div class="row">
      <div class="col">
        <label for="amount" class="form-label">Wait Period</label>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <input
          id="amount"
          type="text"
          class="form-control"
          v-model="time_duration"
        />
      </div>
      <div class="col-6">
        <select v-model="time_unit" class="form-select">
          <option :value="u" v-for="u in Object.keys(time_units)" :key="u">
            {{ u }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reputation.workflow.editactions.editwaitaction",
  props: {
    value: Object,
  },
  data: () => {
    return {
      time_units: {
        Seconds: 1,
        Minutes: 60,
        Hours: 60 * 60,
        Days: 24 * 60 * 60,
      },
      time_unit: "Days",
      time_duration: 0,
      actor_data: {
        relative_seconds: 0,
      },
    };
  },
  watch: {
    time_unit: function () {
      this.actor_data.relative_seconds =
        this.time_units[this.time_unit] * this.time_duration;
      this.$emit("input", this.actor_data);
    },
    time_duration: function () {
      this.actor_data.relative_seconds =
        this.time_units[this.time_unit] * this.time_duration;
      this.$emit("input", this.actor_data);
    },
    actor_data: function () {
      this.$emit("input", this.actor_data);
    },
  },
  created() {
    let v = JSON.parse(JSON.stringify(this.value));
    if (v.relative_seconds) {
      let best_duration = Object.keys(this.time_units)
        .sort((a, b) => this.time_units[b] - this.time_units[a])
        .filter((d) => v.relative_seconds % this.time_units[d] == 0)[0];
      this.time_unit = best_duration;
      this.time_duration = parseInt(
        v.relative_seconds / this.time_units[best_duration]
      );
    }
    console.log(v);
  },
};
</script>
