<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label for="subject" class="form-label">Email Subject</label>
        <input
          id="subject"
          type="text"
          class="form-control"
          v-model="subject"
        />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <label for="text-template" class="form-label">Text template</label>
        <select id="text-template" class="form-select" v-model="text_template">
          <option value="" disabled="">Select a template</option>
          <option
            v-for="template in text_templates"
            :key="template.id"
            :value="template.private_key"
          >
            {{ template.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <label for="html-template" class="form-label">HTML template</label>
        <select id="html-template" class="form-select" v-model="html_template">
          <option value="" disabled="">Select a template</option>
          <option
            v-for="template in html_templates"
            :key="template.id"
            :value="template.private_key"
          >
            {{ template.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../../../services/ReputationManagementService";
export default {
  name: "reputation.workflow.editactions.editemailaction",
  props: {
    value: Object,
  },
  data: () => {
    return {
      text_templates: [],
      html_templates: [],
      subject: "",
      html_template: null,
      text_template: null,
    };
  },
  methods: {
    save() {
      this.$emit("input", {
        subject: this.subject,
        html_template: this.html_template,
        text_template: this.text_template,
      });
    },
  },
  watch: {
    text_template: function () {
      this.save();
    },
    html_template: function () {
      this.save();
    },
    subject: function () {
      this.save();
    },
  },
  async mounted() {
    let v = JSON.parse(JSON.stringify(this.value));
    let templates = await ReputationManagementService.getTemplates(
      this.$auth.activeCompany
    );
    console.log(v);
    templates
      .filter((t) => t.type === "text/html")
      .forEach((t) => this.html_templates.push(t));
    templates
      .filter((t) => t.type === "text")
      .forEach((t) => this.text_templates.push(t));
    this.subject = v.subject;
    this.html_template = v.html_template || "";
    this.text_template = v.text_template || "";
  },
};
</script>
