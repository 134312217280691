<template>
  <b-modal size="sm" id="edit-cohort-modal">
    <template #modal-header="{ close }">
      <h5 class="modal-title" v-if="editing">Edit Survey Group</h5>
      <h5 class="modal-title" v-else>New Survey Group</h5>
      <button type="button" class="btn-close" @click.prevent="close()"></button>
    </template>

    <div v-if="machines.length > 0">
      <div class="row">
        <div class="col mb-3">
          <label for="cohort-name" class="form-label">Group Name</label>
          <input
            type="text"
            id="cohort-name"
            v-model="$v.cohort.name.$model"
            class="form-control"
          />
        </div>
      </div>

      <div class="row">
        <div class="col mb-3">
          <label for="cohort-name" class="form-label">Workflow</label>
          <select class="form-select" v-model="$v.cohort.machine.$model">
            <option
              :value="machine.id"
              v-for="machine in machines"
              :key="machine.id"
            >
              {{ machine.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
      <div class="empty-img">
        <img
          src="@/assets/illustrations/undraw_Completed_steps.svg"
          alt=""
          height="128"
        />
      </div>
      <p class="empty-title">No workflows yet</p>
      <p class="empty-subtitle text-muted">
        In order to create a group, you need to have a least one workflow.
      </p>
      <div class="empty-action">
        <router-link :to="`/reputation/workflows/`" class="btn btn-primary">
          Create a workflow
        </router-link>
      </div>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <a
        href="#"
        @click.prevent="
          reset();
          cancel();
        "
        class="btn btn-link link-secondary"
        data-bs-dismiss="modal"
      >
        Cancel
      </a>
      <a
        href="#"
        :disabled="$v.$invalid"
        @click.prevent="save(ok)"
        :class="[
          'btn',
          'btn-primary',
          'ms-auto',
          $v.$invalid ? 'disabled' : '',
        ]"
        v-if="!saving"
      >
        <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          v-if="!editing"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        <span v-if="editing">Save group</span>
        <span v-else>Add new group</span>
      </a>
      <a
        href="#"
        :class="['btn', 'btn-primary', 'ms-auto', 'disabled']"
        @click.prevent
        v-else
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
        Saving
      </a>
    </template>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ReputationManagementService from "../../../services/ReputationManagementService";

export default {
  name: "reputation.cohort.edit-cohort-modal",
  props: {
    reviewFlow: Object,
    editing: Object,
  },
  data: function () {
    return {
      cohort: {
        name: "",
        machine: null,
      },
      machines: [],
      saving: false,
    };
  },
  methods: {
    updateEditing() {
      if (this.editing) {
        //update editing
        this.cohort.name = this.editing.name;
        this.cohort.machine = this.editing.machine;
      }
    },
    reset() {
      this.cohort.name = "";
      this.cohort.machine = "";
      this.$v.$reset();
      this.saving = false;
      this.updateEditing();
    },
    async save(ok) {
      if (!this.$v.$invalid) {
        this.saving = true;
        let cohort = {};
        cohort.name = this.cohort.name;
        cohort.machine = this.cohort.machine;
        cohort.review_flow = this.reviewFlow.id;
        try {
          if (this.editing) {
            cohort.id = this.editing.id;
            console.log(cohort);
            await ReputationManagementService.updateCohort(
              this.$auth.activeCompany,
              cohort
            );
          } else {
            await ReputationManagementService.addCohort(
              this.$auth.activeCompany,
              cohort
            );
          }
        } catch (e) {
          this.saving = false;
          return;
        }
        ok();
        this.$emit("saved-cohort");
        this.reset();
      }
    },
    async loadMachines() {
      this.machines = await ReputationManagementService.getWorkflows(
        this.$auth.activeCompany
      );
    },
  },
  validations: {
    cohort: {
      name: {
        required,
      },
      machine: {
        required,
      },
    },
  },
  watch: {
    editing: function () {
      if (this.editing) {
        this.updateEditing();
      } else {
        this.reset();
      }
    },
  },
  async mounted() {
    this.updateEditing();
    await this.loadMachines();
  },
};
</script>
