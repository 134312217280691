<template>
  <div :class="['workflow', noBorder ? '' : 'border', 'border-2', 'p-4']">
    <div class="center-line"></div>
    <div class="card mb-4">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-auto">
            <span class="bg-grey text-black avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-player-play"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M7 4v16l13 -8z"></path>
              </svg>
            </span>
          </div>
          <div class="col">
            <div class="font-weight-medium">
              Cohort member added, workflow starts.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card mb-4"
      v-for="(w, id) in workflow.raw_machine.workflow"
      :key="id"
    >
      <div class="card-body">
        <div :class="[editable ? 'mb-3' : '']">
          <edit-action v-if="editing[id]" v-model="editing[id]"></edit-action>
          <display-action v-else :actor="w"></display-action>
        </div>
        <div class="d-flex" v-if="editable">
          <button
            type="button"
            @click="$emit('remove-action', id)"
            class="btn btn-outline-danger btn-icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-trash"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="4" y1="7" x2="20" y2="7"></line>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
            </svg>
          </button>

          <button
            type="button"
            class="ms-auto btn btn-primary"
            v-if="editing[id]"
            @click="save(id)"
          >
            Save
          </button>
          <button
            type="button"
            @click="edit(id)"
            class="btn btn-outline btn-icon ms-auto"
            v-else
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-edit"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
              ></path>
              <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
              <line x1="16" y1="5" x2="19" y2="8"></line>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="mb-4 text-center addbutton" v-if="editable">
      <button @click.prevent="addAction" class="btn btn-outline bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circle-plus"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="12" cy="12" r="9"></circle>
          <line x1="9" y1="12" x2="15" y2="12"></line>
          <line x1="12" y1="9" x2="12" y2="15"></line>
        </svg>

        Add workflow action
      </button>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-auto">
            <span class="bg-green text-white avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-check"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </span>
          </div>
          <div class="col">
            <div class="font-weight-medium">Workflow is complete.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.workflow {
  position: relative;
}
.card {
  z-index: 5;
}
.addbutton {
  z-index: 500;
  position: relative;
}
.center-line {
  width: 0px;
  height: calc(100% - 75px);
  position: absolute;
  border: dashed 2px rgba(101, 109, 119, 0.16);
  left: calc(50% - 1px);
  z-index: 1;
}
</style>

<script>
import DisplayAction from "./DisplayAction.vue";
import EditAction from "./EditAction.vue";

export default {
  name: "reputation.workflow.render-workflow",
  components: { EditAction, DisplayAction },
  props: {
    workflow: Object,
    editable: Boolean,
    noBorder: Boolean,
  },
  data: () => {
    return {
      editing: {},
    };
  },
  methods: {
    edit(id) {
      this.$set(this.editing, id, this.workflow.raw_machine.workflow[id]);
    },
    save(id) {
      this.$emit("save-action", id, this.editing[id]);
      this.$delete(this.editing, id);
    },
    addAction() {
      this.editing[this.workflow.raw_machine.workflow.length] = {
        actor: "",
        actor_data: {},
      };
      this.$emit("new-action");
    },
  },
};
</script>
