<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label for="action-type" class="form-label"> Action Type </label>

        <select id="action-type" class="form-select" v-model="selectedAction">
          <option value="" selected="" disabled="">Select an action</option>
          <option
            :value="action"
            v-for="action in Object.keys(actions)"
            :key="action"
          >
            {{ action }}
          </option>
        </select>
      </div>
    </div>
    <component
      v-if="actions[selectedAction]"
      :is="actions[selectedAction]"
      v-model="action_data"
    ></component>
  </div>
</template>

<script>
import EditWaitAction from "./EditActions/EditWaitAction.vue";
import EditWaitWindowAction from "./EditActions/EditWaitWindowAction.vue";
import EditEmailAction from "./EditActions/EditEmailAction.vue";

export default {
  components: { EditWaitAction, EditEmailAction, EditWaitWindowAction },
  name: "reputation.workflow.edit-action",
  props: {
    value: Object,
  },
  data: () => {
    return {
      actions: {
        "Send e-mail": "edit-email-action",
        "Wait duration": "edit-wait-action",
        "Wait until time window": "edit-wait-window-action",
      },
      pyActors: {
        "Send e-mail": "email",
        "Wait duration": "wait_relative",
        "Wait until time window": "wait_window",
      },
      selectedAction: "",
      action_data: {},
      actor: {
        actor: "",
        actor_data: {},
      },
    };
  },
  watch: {
    selectedAction: function () {
      this.actor.actor = this.pyActors[this.selectedAction];
      this.$emit("input", this.actor);
    },
    action_data: function () {
      this.actor.actor_data = this.action_data;
      this.$emit("input", this.actor);
    },
  },
  created() {
    let v = JSON.parse(JSON.stringify(this.value));
    console.log(v);
    this.selectedAction = Object.keys(this.pyActors)
      .filter((k) => this.pyActors[k] === v.actor)
      .reduce((_, c) => c, "");
    this.action_data = v.actor_data;
  },
};
</script>
