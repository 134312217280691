var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"bg-primary text-white avatar"},[_c('svg',{staticClass:"icon icon-tabler icon-tabler-calendar-time",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","stroke-width":"1","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"}}),_c('circle',{attrs:{"cx":"18","cy":"18","r":"4"}}),_c('path',{attrs:{"d":"M15 3v4"}}),_c('path',{attrs:{"d":"M7 3v4"}}),_c('path',{attrs:{"d":"M3 11h16"}}),_c('path',{attrs:{"d":"M18 16.496v1.504l1 1"}})])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v("Wait until time window:")]),_c('div',_vm._l((_vm.days),function(day,day_id){return _c('span',{key:day_id,class:[
            'badge',
            'badge-pill',
            'me-1',
            'mb-1',
            _vm.actor.actor_data.window_week_day.includes(day_id)
              ? 'bg-green-lt'
              : 'text-dark' ]},[_vm._v(" "+_vm._s(day)+" ")])}),0),_c('div',[_vm._v(" "+_vm._s(_vm.timeRange)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }