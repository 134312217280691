<template>
  <div>
    <div class="row">
      <div class="col">
        <label class="form-label">Select active days</label>
        <label
          class="form-check form-switch"
          v-for="(day, idx) in days"
          :key="idx"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :value="idx"
            v-model="selectedDays"
          />
          <span class="form-check-label">{{ day }}</span>
        </label>
      </div>
      <div class="col">
        <label class="form-label">Window start</label>
        <select class="form-select mb-3" v-model="startTime">
          <option
            v-for="possibleStartTime in startTimes"
            :value="possibleStartTime"
            :key="`${possibleStartTime}-start`"
          >
            {{ formatTime(possibleStartTime) }}
          </option>
        </select>

        <label class="form-label">Window end</label>
        <select class="form-select" v-model="endTime">
          <option
            v-for="possibleEndTime in endTimes"
            :key="`${possibleEndTime}-end`"
            :value="possibleEndTime"
          >
            {{ formatTime(possibleEndTime) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "reputation.workflow.editactions.editwaitwindowaction",
  props: {
    value: Object,
  },
  methods: {
    formatTime(time) {
      return format(new Date(1970, 1, 1, time, 0), "h:mm aaa");
    },
    save() {
      let hoursStart = this.startTime;
      let hoursEnd = this.endTime;
      console.log(hoursStart, hoursEnd);
      let hours = [];
      for (let i = hoursStart; i <= hoursEnd; i++) {
        hours.push(i);
      }
      this.$emit("input", {
        window_week_day: this.selectedDays,
        window_hour: hours,
        window_timezone: "America/New_York",
      });
    },
  },
  data: () => {
    return {
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      selectedDays: [],
      startTime: 0,
      endTime: 0,
    };
  },
  computed: {
    startTimes: function () {
      return [...Array(23).keys()];
    },
    endTimes: function () {
      return this.startTimes.slice(this.startTime + 1);
    },
  },
  watch: {
    selectedDays: function () {
      this.save();
    },
    startTime: function () {
      this.save();
    },
    endTime: function () {
      this.save();
    },
  },
  mounted() {
    let v = JSON.parse(JSON.stringify(this.value));

    console.log(v);
  },
};
</script>
