<template>
  <div class="row">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="bg-primary text-white avatar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-send"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="10" y1="14" x2="21" y2="3"></line>
            <path
              d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"
            ></path>
          </svg>
        </span>
      </div>
      <div class="col">
        <div class="font-weight-medium">Send email:</div>
        <div class="text-muted mb-1">
          <i>
            {{ actor.actor_data.subject }}
          </i>
        </div>
        <div class="row">
          <div class="col">
            <a href="#" @click.prevent="preview(html_template)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-code me-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="7 8 3 12 7 16"></polyline>
                <polyline points="17 8 21 12 17 16"></polyline>
                <line x1="14" y1="4" x2="10" y2="20"></line>
              </svg>
              <span v-if="html_template">{{ html_template.name }}</span>
              <span v-else>HTML Template</span>
            </a>
          </div>
          <div class="col">
            <a href="#" @click.prevent="preview(text_template)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-blockquote me-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M6 15h15"></path>
                <path d="M21 19h-15"></path>
                <path d="M15 11h6"></path>
                <path d="M21 7h-6"></path>
                <path d="M9 9h1a1 1 0 1 1 -1 1v-2.5a2 2 0 0 1 2 -2"></path>
                <path d="M3 9h1a1 1 0 1 1 -1 1v-2.5a2 2 0 0 1 2 -2"></path>
              </svg>
              <span v-if="text_template">{{ text_template.name }}</span>
              <span v-else>Text template</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <preview-template-modal
      :privateKey="previewPrivateKey"
      :previewUnsaved="false"
      :code="``"
      :additionalId="`-${additionalId}`"
    ></preview-template-modal>
  </div>
</template>

<script>
import ReputationManagementService from "../../../../services/ReputationManagementService";
import PreviewTemplateModal from "../../Template/PreviewTemplateModal.vue";
export default {
  components: { PreviewTemplateModal },
  name: "reputation.workflow.displayaction.sendemailaction",
  props: {
    actor: Object,
  },
  data: function () {
    return {
      html_template: null,
      text_template: null,
      previewPrivateKey: null,
      additionalId: Math.floor(Math.random() * 100000000),
    };
  },
  methods: {
    preview(template) {
      this.previewPrivateKey = template.private_key;
      this.$nextTick(() => {
        this.$bvModal.show(`preview-template-modal-${this.additionalId}`);
      });
    },
  },
  mounted() {
    ReputationManagementService.getTemplate(
      this.$auth.activeCompany,
      this.actor.actor_data.html_template
    )
      .then((t) => (this.html_template = t))
      .catch((e) => console.log(e));
    ReputationManagementService.getTemplate(
      this.$auth.activeCompany,
      this.actor.actor_data.text_template
    )
      .then((t) => (this.text_template = t))
      .catch((e) => console.log(e));
  },
};
</script>
