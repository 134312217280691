<template>
  <b-modal :id="`preview-template-modal${additionalId}`" size="lg">
    <template #modal-header="{ close }">
      <h5 class="modal-title" v-if="!loading && !previewUnsaved">
        {{ template_preview.template.name }} Preview
      </h5>
      <h5 class="modal-title" v-else>Preview Changes</h5>
      <button type="button" class="btn-close" @click.prevent="close()"></button>
    </template>

    <iframe
      class="preview border border-2"
      v-if="!loading"
      :srcdoc="template_preview.preview"
      sandbox=""
    ></iframe>

    <template #modal-footer>
      <select class="form-select" v-model="member" @change="loadPreview">
        <option v-for="(m, idx) in members" :key="m.id" :value="idx">
          Preview for {{ m.data.first_name }} {{ m.data.last_name }}
        </option>
      </select>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.preview {
  width: 100%;
  min-height: 256px;
}
</style>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";
export default {
  name: "reputation.template.preview-template-modal",
  props: {
    privateKey: String,
    code: String,
    previewUnsaved: Boolean,
    additionalId: String,
  },
  data: () => {
    return {
      loading: true,
      template_preview: null,
      members: [],
      member: null,
    };
  },
  methods: {
    async loadPreview() {
      this.loading = true;
      if (!this.previewUnsaved && !this.privateKey) {
        return;
      }
      if (this.previewUnsaved) {
        this.template_preview =
          await ReputationManagementService.getUnsavedTemplatePreview(
            this.$auth.activeCompany,
            { code: this.code },
            this.members[this.member].id
          );
      } else {
        this.template_preview =
          await ReputationManagementService.getTemplatePreview(
            this.$auth.activeCompany,
            this.privateKey,
            this.members[this.member].id
          );
      }
      this.loading = false;
    },
  },
  async mounted() {
    let company = this.$auth.activeCompany;
    let members = await ReputationManagementService.getMembers(company);
    this.members = members;
    this.member = 0;
    this.$root.$on("bv::modal::show", async () => {
      await this.loadPreview();
    });
  },
};
</script>
