<template>
  <div class="card" data-v-step="5">
    <div class="card-header d-flex align-items-center">
      <h3 class="card-title">Customers</h3>
      <button
        class="ms-auto btn btn-sm btn-outline"
        v-b-modal.import-csv-modal
        v-if="$auth.planIncludes('request-reviews-bulk', $auth.highestPlan)"
      >
        Bulk add customers
      </button>
      <button
        class="ms-auto btn btn-sm btn-outline disabled"
        :disabled="true"
        v-else
      >
        Bulk add customers (requires featured listing)
      </button>
      <button
        v-b-modal.new-member-modal
        @click="editing(null)"
        class="ms-3 btn btn-sm btn-outline"
      >
        Add customer
      </button>
    </div>
    <div v-if="loadedMembers">
      <div
        :class="[tutorial ? '' : 'table-responsive']"
        v-if="cohortMembers.length > 0"
      >
        <table class="table card-table table-vcenter text-nowrap datatable">
          <thead>
            <tr>
              <th></th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Company</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="member in cohortMembers"
              :key="member.id"
              data-v-step="7"
            >
              <td data-v-step="8">
                <workflow-status :member="member"></workflow-status>
              </td>
              <td>{{ member.data.first_name }}</td>
              <td>{{ member.data.last_name }}</td>
              <td>{{ member.data.email }}</td>
              <td>{{ member.data.company_name }}</td>
              <td>
                <a
                  href="#"
                  v-b-modal.new-member-modal
                  data-v-step="9"
                  @click.prevent="editing(member)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-edit"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
                    ></path>
                    <path
                      d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"
                    ></path>
                    <line x1="16" y1="5" x2="19" y2="8"></line>
                  </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="empty" v-else>
        <div class="empty-img">
          <img
            src="@/assets/illustrations/undraw_Team.svg"
            alt=""
            height="128"
          />
        </div>
        <p class="empty-title">No customers added</p>
        <p class="empty-subtitle text-muted">
          Add customers and we will ask them to leave you a review.
        </p>
        <div class="empty-action">
          <a
            href="#"
            v-b-modal.new-member-modal
            @click="editing(null)"
            class="btn btn-primary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-user-plus"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
              <path d="M16 11h6m-3 -3v6"></path>
            </svg>
            Add first customer
          </a>
          <template
            v-if="$auth.planIncludes('request-reviews-bulk', $auth.highestPlan)"
          >
            or
            <a
              class="btn btn-primary"
              v-b-modal.import-csv-modal
              @click="trackCsv"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-upload"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                <polyline points="7 9 12 4 17 9"></polyline>
                <line x1="12" y1="4" x2="12" y2="16"></line>
              </svg>
              Import CSV
            </a>
          </template>
        </div>
      </div>
    </div>

    <import-csv-modal
      :cohort="cohort"
      @saved-cohort="reloadCohortMembers"
    ></import-csv-modal>
    <add-member-modal
      :cohort="cohort"
      @saved-member="reloadCohortMembers"
      :editing="editingMember"
      :tutorial="tutorial"
    ></add-member-modal>
  </div>
</template>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";
import TrackingService from "../../../services/TrackingService";

import AddMemberModal from "./AddMemberModal.vue";
import ImportCsvModal from "./ImportCsvModal.vue";
import WorkflowStatus from "./WorkflowStatus.vue";

export default {
  name: "reputation.cohort.cohort-member-table",
  components: {
    AddMemberModal,
    ImportCsvModal,
    WorkflowStatus,
  },
  props: {
    cohort: Object,
    tutorial: Boolean,
  },
  data() {
    return {
      cohortMembers: [],
      editingMember: null,
      loadedMembers: false,
    };
  },
  methods: {
    async reloadCohortMembers() {
      let c = await ReputationManagementService.getCohortMembers(
        this.$auth.activeCompany,
        this.cohort.id
      );
      this.$set(this, "cohortMembers", c);
      this.$emit("reload-counts");
      this.loadedMembers = true;
    },

    editing(member) {
      this.$set(this, "editingMember", member);
    },

    trackCsv() {
      TrackingService.track("opened bulk upload tool");
    },
  },
  async mounted() {
    await this.reloadCohortMembers();
  },
};
</script>
