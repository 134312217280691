<template>
  <div data-v-step="3">
    <div class="row">
      <div class="col">
        <ol class="breadcrumb breadcrumb-arrows mb-3" aria-label="breadcrumbs">
          <li class="breadcrumb-item">
            <router-link :to="`/reputation/review-flows/`">
              Surveys
            </router-link>
          </li>
          <li class="breadcrumb-item" data-v-step="14" v-if="reviewFlow">
            <router-link :to="`/reputation/review-flow/${reviewFlow.id}/`">
              {{ reviewFlow.name }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page" v-if="cohort">
            <a href="#">{{ cohort.name }}</a>
          </li>
        </ol>
      </div>
      <div class="col-auto">
        <button v-b-modal.edit-cohort-modal class="btn btn-sm btn-outline">
          Edit Survey Group
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <cohort-member-table
          :cohort="cohort"
          v-if="cohort"
        ></cohort-member-table>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-header d-flex">
            <div>
              <h3 class="card-title">Workflow</h3>
              <div class="card-subtitle" v-if="workflow">
                {{ workflow.name }}
              </div>
            </div>

            <button
              v-b-modal.edit-cohort-modal
              class="ms-auto btn btn-sm btn-outline"
            >
              Change
            </button>
          </div>
          <render-workflow
            v-if="workflow"
            :editable="false"
            :workflow="workflow"
            :noBorder="true"
            data-v-step="6"
          ></render-workflow>
        </div>
      </div>
    </div>

    <edit-cohort-modal
      :reviewFlow="reviewFlow"
      :editing="cohort"
      @saved-cohort="reloadCohort"
    ></edit-cohort-modal>
  </div>
</template>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";
import EditCohortModal from "./Cohort/EditCohortModal.vue";
import RenderWorkflow from "./Workflow/RenderWorkflow.vue";
import CohortMemberTable from "./Cohort/CohortMemberTable.vue";

export default {
  components: {
    RenderWorkflow,
    EditCohortModal,
    CohortMemberTable,
  },
  name: "reputation.cohort.detail",
  data: () => {
    return {
      cohort: null,
      reviewFlow: null,
      cohortMembers: [],
      workflow: null,
      editingMember: null,
      loadedMembers: false,
    };
  },
  props: {
    tutorial: Boolean,
  },
  methods: {
    async reloadCohort() {
      let c = await ReputationManagementService.getCohort(
        this.$auth.activeCompany,
        this.$route.params.id
      );
      this.cohort = c;
      let w = await ReputationManagementService.getWorkflow(
        this.$auth.activeCompany,
        c.machine
      );
      this.$set(this, "workflow", w);
    },
  },
  async mounted() {
    this.reviewFlow = await ReputationManagementService.getReviewFlow(
      this.$auth.activeCompany,
      this.$route.params.review_flow_id
    );
    await this.reloadCohort();
    if (this.tutorial || this.$route.query.tutorial) {
      this.$nextTick(() => {
        this.$tours["getting_started_tutorial"].start(4);
      });
    }
  },
};
</script>
