<template>
  <div class="row">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="bg-primary text-white avatar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-calendar-time"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"
            ></path>
            <circle cx="18" cy="18" r="4"></circle>
            <path d="M15 3v4"></path>
            <path d="M7 3v4"></path>
            <path d="M3 11h16"></path>
            <path d="M18 16.496v1.504l1 1"></path>
          </svg>
        </span>
      </div>
      <div class="col">
        <div class="font-weight-medium">Wait until time window:</div>
        <div>
          <span
            :class="[
              'badge',
              'badge-pill',
              'me-1',
              'mb-1',
              actor.actor_data.window_week_day.includes(day_id)
                ? 'bg-green-lt'
                : 'text-dark',
            ]"
            v-for="(day, day_id) in days"
            :key="day_id"
          >
            {{ day }}
          </span>
        </div>
        <div>
          {{ timeRange }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "reputation.workflow.displayaction.waitwindowaction",
  props: {
    actor: Object,
  },
  data: () => {
    return {
      //days: ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"],
      days: ["S", "M", "T", "W", "T", "F", "S"],
    };
  },
  computed: {
    timeRange() {
      let hours = this.actor.actor_data.window_hour;
      let tz = this.actor.actor_data.window_timezone;
      let start = format(new Date(1970, 1, 1, hours[0], 0), "h:mm aaa");
      let end = format(
        new Date(1970, 1, 1, hours[hours.length - 1], 0),
        "h:mm aaa"
      );
      return `${start} - ${end} ${tz}`;
    },
  },
};
</script>
