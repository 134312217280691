<template>
  <div>
    <component
      :is="actorMap[actor.actor]"
      :actor="actor"
      v-if="actorMap[actor.actor]"
    ></component>
    <pre v-else>{{ actor }}</pre>
  </div>
</template>

<script>
import DisplayWaitAction from "./DisplayActions/DisplayWaitAction.vue";
import DisplayWaitWindowAction from "./DisplayActions/DisplayWaitWindowAction.vue";
import DisplaySendEmailAction from "./DisplayActions/DisplaySendEmailAction.vue";

export default {
  name: "reputation.workflow.displayaction",
  components: {
    DisplayWaitAction,
    DisplayWaitWindowAction,
    DisplaySendEmailAction,
  },
  props: {
    actor: Object,
  },
  data: () => {
    return {
      actorMap: {
        wait_relative: "display-wait-action",
        wait_window: "display-wait-window-action",
        email: "display-send-email-action",
      },
    };
  },
};
</script>
